import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TableTodayReward from './components/TableTodayReward';
import ListTodayStockData from './components/ListTodayStockData';

// const API_URL = 'http://localhost:3300/api';
const API_URL = 'https://bnapi.rootth.com/api';

const formatDate = (dateTimeString) => {
  const dateTime = new Date(dateTimeString);

  const day = String(dateTime.getDate()).padStart(2, '0');
  const month = String(dateTime.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = dateTime.getFullYear();
  const hours = String(dateTime.getHours()).padStart(2, '0');
  const minutes = String(dateTime.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year}`;
};

const App = () => {
  const [data, setData] = useState(null);
  const [dataStock, setDataStock] = useState([]);
  const [rewardResult, setRewardResult] = useState(null);
  const [rewardHistoryResult, setRewardHistoryResult] = useState(null);

  const fetchLotteryRewardResult = async () => {
    try {
      const requestBody = {
        lotteryId: '6505ecc4c4d607409d6f3b1f',
      };

      const response = await axios.post(
        `${API_URL}/lottery-reward-result-hs`,
        requestBody
      );

      // Check if the request was successful
      if (response.status === 200) {
        console.log('response.data : ', response.data);
        // console.log('response.data : ', response.data.lotteryRewardResult);
        // console.log('response.data.results : ', response.data.results.dateString);
        setData(response.data.results);
        setDataStock(response.data.results.stocks);
        setRewardResult(response.data.lotteryRewardResult);
        setRewardHistoryResult(response.data.historyReward);

        // return response.data.results;
      } else {
        console.error(
          'API request failed:',
          response.status,
          response.statusText
        );
        return null;
      }
    } catch (error) {
      console.error(
        'An error occurred while fetching data from the API:',
        error
      );
      return null;
    }
  };

  useEffect(() => {
    fetchLotteryRewardResult();

    const interval = setInterval(async () => {
      await fetchLotteryRewardResult();
    }, 3 * 60 * 1000); // Fetch data every 3 minutes

    return () => clearInterval(interval);
  }, []);

  const mapStockName = (originalName) => {
    const nameMappings = {
      HangSengIndex: 'Hang Seng Index',
      HangSengCN: 'Hang Seng China Enterprises Index',
      HangSengTI: 'Hang Seng TECH Index',
      HangSengCI: 'Hang Seng Composite Index',
    };

    return nameMappings[originalName] || originalName;
  };

  return (
    <div className='container-fluid px-0'>
      <div className='row mx-0'>
        <div className='col-12 col-xl-8 offset-xl-2 px-0'>
          <div id='hkindex'>
            <div className='header'>
              <img src='assets/img/hk-header.png' />
            </div>
            <div className='top-nav'>
              <ul>
                <li>
                  <a href='#'>About Us</a>
                </li>
                <li>
                  <a href='#'>Contact Us</a>
                </li>
                <li>
                  <a href='#'>繁</a>
                </li>
                <li>
                  <a href='#'>简</a>
                </li>
              </ul>
            </div>
            <div className='nav'>
              <img src='assets/img/logo_hangseng.png' />
              <div className='menu'>
                <ul>
                  <li>
                    <a href='#'>Indexes</a>
                  </li>
                  <li>
                    <a href='#'>Our Services</a>
                  </li>
                  <li>
                    <a href='#'>ESG Service</a>
                  </li>
                  <li>
                    <a href='#'>Resources</a>
                  </li>
                  <li>
                    <a href='#'>Newsroom</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='content'>
              <div className='arrow-left'>
                <span>
                  <i className='fas fa-chevron-left' />
                </span>
              </div>
              <div className='index-box'>
                <div className='index-tab'>
                  <ul>
                    <li className='first'>
                      HONG KONG-LISTED
                      <div className='arrow' />
                    </li>
                    <li className='second'>MAINLAND-LISTED</li>
                    <li className='third'>CROSS-MARKET</li>
                  </ul>
                </div>
                <div className='index-cont m-0'>
                  <ListTodayStockData
                    dataStock={dataStock}
                    data={data}
                    mapStockName={mapStockName}
                  />
                </div>
                <div className='today-result'>
                  <table className='table table-bordered'>
                    <TableTodayReward rewardResult={rewardResult} />
                  </table>
                </div>
              </div>
              <div className='arrow-right'>
                <span>
                  <i className='fas fa-chevron-right' />
                </span>
              </div>
            </div>
            <div className='mob-index'>
              <div className='index-box'>
                <div className='index-tab'>
                  <ul>
                    <li className='first'>HONG KONG-LISTED</li>
                  </ul>
                </div>
                <div className='index-cont m-0'>
                  <ListTodayStockData
                    dataStock={dataStock}
                    data={data}
                    mapStockName={mapStockName}
                  />
                </div>
                <div className='today-result'>
                  <table className='table table-bordered'>
                    <TableTodayReward rewardResult={rewardResult} />
                  </table>
                </div>
              </div>
              <div className='second'>
                <span>MAINLAND-LISTED</span>
                <i className='fas fa-chevron-down' />
              </div>
              <div className='third'>
                <span>CROSS-MARKET</span>
                <i className='fas fa-chevron-down' />
              </div>
            </div>
            <div className='history mt-3'>
              <h3>ผลย้อนหลัง</h3>
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th rowSpan={2}>วันที่</th>
                    <th colSpan={2}>ปิดเช้า</th>
                    <th colSpan={2}>ปิดเย็น</th>
                  </tr>
                  <tr>
                    <th>บน</th>
                    <th>ล่าง</th>
                    <th>บน</th>
                    <th>ล่าง</th>
                  </tr>
                </thead>
                <tbody>
                  {rewardHistoryResult?.map((item) => (
                    <tr key={item._id}>
                      <td>{formatDate(item.dateString)}</td>
                      <td>{item.morningDigits3 === "0" ? "000" : item.morningDigits3}</td>
                      <td>{item.morningDigits2 === "0" ? "00" : item.morningDigits2}</td>
                      <td>{item.eveningDigits3 === "0" ? "000" : item.eveningDigits3}</td>
                      <td>{item.eveningDigits2 === "0" ? "00" : item.eveningDigits2}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='hk-final-footer' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
